.formContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }
  
  .subSection {
    background: #f8f9fa;
    border: 1px solid #dee2e6;
    padding: 1rem;
    border-radius: 4px;
    height: fit-content;
    align-content: start;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
  
    & + .subSection {
      margin-top: 1.3rem;
    }
  }
  
  .sectionBasicData {
    align-content: start;
    height: 100%;
  }
  
  .section {
    height: fit-content;
    align-content: start;
    height: fit-content;
  }
  
  .sectionTitle {
    margin-bottom: 1rem;
    font-weight: bold;
  }
  
  .inputGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }
  
  .inputGroupFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    gap: 0.75rem;
  }
  
  .inputGroupFlexFullHeight {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    gap: 0.75rem;
    height: 100%;
  }
  
  .inputGroup label {
    margin-bottom: 0.5rem;
  }
  
  .inputGroup input,
  .inputGroup select,
  .inputGroup textarea {
    padding: 0.35rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  
  .inputGroupFlex input,
  .inputGroupFlex select,
  .inputGroupFlex textarea {
    padding: 0.35rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }
  
  .fileInput {
    border: 0;
    margin-bottom: 1rem;
  }
  
  .boxButtom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: 3;
  
    button[type="submit"] {
      width: 20rem;
      margin-top: 1rem;
      margin-left: 1rem;
      font-size: 16px;
      height: 40px;
      border: none;
    }
  
    button[type="button"] {
      width: 15rem;
      margin-top: 1rem;
      background-color: var(--red-500);
      color: var(--white);
      border: none;
      border-radius: 5px;
      height: 40px;
    }
  }
  
  .spinLoading {
    position: fixed !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(22, 118, 253, 0.25) !important;
    border-radius: 4px;
    top: 25% !important;
    width: 10% !important;
    height: 15% !important;
    margin-left: 45% !important;
    margin-top: 10% !important;
  }
  
  @media (max-width: 1024px) {
    .formContainer {
      grid-template-columns: 1fr;
    }
    .inputGroupFlex {
      flex-direction: column;
    }
    .boxButtom {
      grid-column-start: 1;
      grid-column-end: 1;
    }
  }
  