.container {
    ul {
        margin-top: 20px;

        li {

            a {
                color: var(--gray-200);
                text-decoration: none;

                &:hover {
                    color: var(--gray-100);
                }
            }
        }
    }
}

.infosContent {
    display: flex;
    margin-right: 2rem;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    .date {
        color: var(--gray-200);
    }
}