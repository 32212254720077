.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 2rem;

    h1 {
        color: var(--blue-900);
        font-size: 2rem;
        margin-bottom: 1rem;
    }

    p {
        color: var(--blue-900);
        font-size: 1rem;
        margin-bottom: 1rem;
    }
}

.form {
    display: flex;
    flex-direction: column;
    width: 35%;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    padding: 1rem;
    border-radius: 5px;

    background-color: var(--blue-900);

    label {
        margin-top: 1.5rem;
        color: var(--white);

        &:nth-child(2) {
            margin-top: 0;
        }

    }

    .passwordField {
        border: none;
        color: var(--white);
        background-color: transparent;
        border: 1px solid var(--white);
        border-radius: 5px;
        outline: none;
        align-items: center;
        display: flex;

        input {
            width: 95%;
            height: auto;
            padding: 10px;
            border: none;
            color: var(--white);
            background-color: transparent;
            outline: none;
        }

        svg {
            cursor: pointer;
            color: var(--white);
        }
    }

    .checkboxContainer {
        margin-top: 1rem;

      label {
        margin-left: 5px;
        color: var(--white);
      }
    }

    button {
      background-color: var(--blue-800); // Adapte à cor do seu tema
      color: var(--white);
      border: 1px solid var(--white);
      padding: 10px;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;
      margin-top: 2rem;

      &:hover {
        filter: brightness(1.1);
      }
    }
  }

  .spinLoading {
    position: fixed !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(22, 118, 253, 0.25) !important;
    border-radius: 4px;
    top: 25% !important;
    width: 10% !important;
    height: 15% !important;
    margin-left: 45% !important;
    margin-top: 10% !important;
}

.error {
    color: red;
    margin-top: 5px;
  }