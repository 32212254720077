.button {
  width: 100%;
  color: var(--white);
  border: 1px solid var(--white);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  font-weight: 600;
  transition: filter 0.2s;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }

  &:hover {
    filter: brightness(0.9);
  }
}
