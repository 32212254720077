.label {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .labelNameUser {
    margin-right: 1rem;
  }

  .labelTitle {
    font-weight: bold;
  }

  .labelRole {
    margin-left: 10px;
  }
}

.containerAction {
  display: flex;
}

.userCard {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #ccc;
  border-radius: 5px;

  .userInfo {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .boxUserLabelCard {
      display: flex;
      width: 16%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin-right: 1rem;

      .labelCard {
        font-weight: bold;
      }
    }

    .userName {
      color: var(--blue-900);
    }

    .userRole {
      color: var(--blue-900);
      font-size: 0.8rem;
    }

    .userStatus {
      color: var(--green-800);
    }
  }

  .userActions {
    display: flex;
    width: 35%;
    align-items: center;
    justify-content: space-between;

    div {
      & + div {
        margin-left: 1rem;
      }
    }
  }
}

.formContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .formItem {
    width: max-content;
    div {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .formButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 1rem;
  }

  .formButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    button[type="submit"] {
      width: 8rem;
      margin-top: 1rem;
      margin-left: 1rem;
      font-size: 16px;
      height: 40px;
      border: none;
    }

    button[type="button"] {
      width: 8rem;
      margin-top: 1rem;
      background-color: var(--red-500);
      font-size: 16px;
      color: var(--white);
      border: none;
      border-radius: 5px;
      height: 40px;
    }
  }
}

@media (max-width: 1080px) {
  .userCard {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .userInfo {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start !important;

    margin-bottom: 1rem;

    .boxUserLabelCard {
      width: 100% !important;
      margin-right: 0;
    }
  }

  .userActions {
    margin-top: 1rem;
    width: 100%;
    align-items: center !important;
    justify-content: center !important;

    .select {
      & + .select {
        margin-left: 1rem;
      }
    }
  }

  .formButtonContainer {
    width: 100%;
    flex-direction: flex;
    align-items: center;
    justify-content: space-between;
  }
}
