.cardsContainer {
    display: flex;
    width: 100%;
    gap: 24px;
    
    .cards{
        width: 33.33%;
    }

    .cardActivities{
        width: 50%;
    }
}


@media screen and (max-width: 768px) {
.cardsContainer {
        display: flex;
        flex-direction: column;
        width: 100%;

        .cards{
            width: 100%;

            &:first-child{
                margin-top: 0;
            }
        }

        .cardActivities{
            width: 100%;
            margin-top: 16px;

            &:last-child{
                margin-top: 0;
            }
        }
    }
}