.containerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.2rem;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e0e0e0;
  height: 4rem;
  width: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.contentSearch,
.contentFilters {
  display: flex;
  align-items: center;
  height: 2.5rem;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
}

.contentSearch {
  flex-grow: 1;
  max-width: 50%;
  margin-right: 1rem;
}

.contentFilters {
  flex-grow: 1;
  max-width: 50%;
  justify-content: space-between;
}

.boxCascader {
  width: 59%;
}

.boxBtnNewStartUp {
  width: 40%;
  z-index: 3;
}

.iconSearch,
.iconFilter {
  color: #9e9e9e;
}

@media (max-width: 1024px) {
  .containerHeader {
    margin-bottom: 3.2rem;
  }
  .boxCascader {
    width: 100%;
  }

  .boxBtnNewStartUp {
    position: fixed;
    width: 17%;
    bottom: 1rem;
    right: 2%;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.3);
  }
}

@media (max-width: 768px) {
  .containerHeader {
    height: auto;
    padding: 0.5rem;
  }

  .boxBtnNewStartUp {
    width: 10rem;
    border-radius: 50%;
  }

  .contentSearch,
  .contentFilters {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .contentFilters {
    flex-direction: column;
  }

  .contentFilters .ant-cascader-picker,
  .contentFilters .ant-btn {
    width: 100%;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 425px) {
  .boxBtnNewStartUp {
    width: 38%;
    right: 4%;
    top: auto;
    bottom: 1rem;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
}
