.loginContainer {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    background-color: transparent;

    .loginForm {
      form {
        display: flex;
        flex-direction: column;
        background-color: transparent;
        height: 100%;
        margin: 20px 0;
  
        input[type="email"],
        input[type="password"] {
          padding: 10px;
          border: unset;
          color: var(--white);
          background-color: transparent;
          border-bottom: 1px solid var(--white);
          outline: unset;
        }

        ::placeholder {
            color: var(--white);
          }

        input:-webkit-autofill {
            -webkit-text-fill-color: var(--gray-200);
            -webkit-box-shadow: 0 0 0 1000px transparent inset;
            background-color: var(--blue-800);
          }

          input:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 var(--gray-200);
          }

        .passwordField {
            border: none;
            color: var(--white);
            background-color: transparent;
            border-bottom: 1px solid var(--white);
            outline: none;
            position: relative;
            display: flex;
            align-items: center;

            input {
                width: 100%;
                height: auto;
                padding: 10px;
                border: none;
                color: var(--white);
                background-color: transparent;
                outline: none;
            }

            svg {
                position: absolute;
                right: 1rem;
                cursor: pointer;
                color: var(--white);
            }
        }
  
        .checkboxContainer {
            display: flex;
            align-items: center;

          label {
            margin-left: 8px;
            color: var(--white);
          }
        }
  
        button {
          background-color: var(--white); // Adapte à cor do seu tema
          color: var(--blue-800);
          border: none;
          padding: 10px;
          border-radius: 4px;
          cursor: pointer;
          transition: background-color 0.3s;
          margin-top: 4rem;
          font-weight: 700;
  
          &:hover {
            filter: brightness(1.1);
          }
        }
      }
    }

    .loginImgContent {
        width: 50%;
        height: 99vh;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-position: 16%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .spinLoading {
        position: fixed !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(22, 118, 253, 0.25) !important;
        border-radius: 4px;
        top: 25% !important;
        width: 10% !important;
        height: 15% !important;
        margin-left: 45% !important;
        margin-top: 10% !important;
    }
}

.imageAndForm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    width: 45%;
    padding: 4rem;

    .links {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 0.875rem;
  
        a {
          font-size: 0.875rem;
          color: var(--white);
          text-decoration: none;
  
          &:hover {
            text-decoration: underline;
          }
        }
      }

    img {
        max-width: 350px;
    }

    h1 {
        color: var(--white);
        text-align: left;
        margin-bottom: 24px;
      }
  
      p {
        text-align: left;
        color: var(--white);
      }
}

.boxVersion {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    bottom: 0;
    right: 0.5rem;
    padding: 0.5rem;
    color: var(--white);
    font-size: 0.625rem;
}

.error {
  color: #ff0000;
  margin-top: 5px;
  font-size: small;
  font-weight: lighter;
}

@media (max-width: 1024px) {
    .imageAndForm {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 auto;
        width: 100%;
        padding: 24px;

        img {
            margin: 0 auto;
            width: 100%;
        }

        h1{
            text-align: center;
        }

        p{
            text-align: center;
        }
   
        .links {
            display: flex;
            flex-direction: column;
            gap: 16px;
            justify-content: space-between;
            margin-bottom: 4rem;

            p{
                text-align: center;
            }
            
            a {
                text-align: center;
                font-size: 0.875rem;
                color: var(--white);
                text-decoration: underline;
            }
          }
    }


    .boxVersion{
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .loginImgContent {
        display: none !important;
    }
}

/* @media (max-width: 768px) {
    .imageAndForm {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 auto;
        width: 100%;
        padding: 24px;
   
        .links {
            display: flex;
            flex-direction: column;
            gap: 16px;
            justify-content: space-between;
            margin-bottom: 4rem;

            p{
                text-align: center;
            }
            
            a {
                text-align: center;
                font-size: 0.875rem;
                color: var(--white);
                text-decoration: none;
            }
          }
    }


    .boxVersion{
        display: flex;
        justify-content: center;
        text-align: center;
    }
}

@media (max-width: 425px) {
    .loginContainer {
        .loginForm {
            .boxLogo {
                img {
                    width: 80%;
                }
            }
        }

        .spinLoading {
            width: 30% !important;
            margin-left: 35% !important;
            margin-top: 30% !important;
        }
    }
} */
  