.sidebar {
  display: block;
  position: fixed;
  background-color: var(--blue-900);
  height: 100vh;
  left: 0;
  bottom: 0;
  width: 15rem;
  z-index: 9999;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.7);
  ::-webkit-scrollbar {
    display: none;
  }

  .labelItemMenu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--white);
    font-weight: 400;

    span {
      margin-left: 1rem;
    }
  }
}

.ulChange {
  ul {
    &:nth-child(2) {
      background: var(--purple-600) !important;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;
  gap: 24px;
  font-size: 0.85rem;
}

.title {
  font-weight: 700;
  font-size: 1.25rem;
  color: var(--white);
}

.profileContainer {
  background-color: var(--gray-100);
  height: 11rem;
  width: 11rem;
  border-radius: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.profileContainerBg {
  background-color: var(--white);
  height: 10.5rem;
  width: 10.5rem;
  border-radius: 10.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.profile {
  object-fit: contain;
  height: 10rem;
  width: 10rem;
  border-radius: 10rem;
}

.userData {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.userName {
  color: var(--white);
}

.userId {
  color: var(--blue-400);
}

.userStatus {
  color: var(--green-800);
  &[dataType="inactive"] {
    color: var(--red-500);
  }
}

.navList {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.navItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--white);
  font-weight: 500;
  cursor: pointer;
  &:hover {
    filter: brightness(1.2);
  }
}

.activeItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  cursor: pointer;
  color: var(--blue-400);
}

@media only screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }
}
