.container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--blue-800);
    height: 100vh;

    h1 {
        font-size: 2rem;
        margin-bottom: 2rem;
        margin-top: 1rem;
        color: var(--white);
    }

    .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 60%;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 5px 8px 10px rgba(255, 255, 255, 0.5);
        background-color: var(--white);

        .form_item {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 20px;

            label {
                font-size: 1.2rem;
                color: var(--blue-800);          
            }

            input {
                width: 100%;
                font-size: 1.2rem;
                border: 1px solid #ccc;
                border-radius: 5px;
            }
        }

        .form_item_password {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 20px;

            label {
                font-size: 1.2rem;
                color: var(--blue-800);          
            }

            input {
                width: 100%;
                font-size: 1.2rem;
                border: 1px solid #ccc;
                border-radius: 5px;

                &:nth-of-type(1) {
                    border: none;
                }
            }
        }

        .form_group_button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            button {
                padding: 10px 20px;
                font-size: 1.2rem;
                border: none;
                border-radius: 5px;
                background-color: #007bff;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}

//responsiveness
@media (max-width: 768px) {
    .container {
        h1 {
            margin-top: 2rem;
            font-size: 1.5rem;
            text-align: center;
        }

        .form {
            max-width: 80%;
        }
    }
}

@media (max-width: 576px) {
    .container {
        h1 {
            font-size: 1.2rem;
        }

        .form {
            max-width: 90%;
        }
    }
}