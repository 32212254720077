* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  :root {
    --white: #ffffff;

    --green-300: #00f0fd;
    --green-400: #00bab6;
    --green-600: #028893;
    --green-700: #025660;

    --green-800: #70e000;

    --blue-gray-600: #1f325a;

    --gray-200: #585a79;
    --gray-100: #a4b9ce;

    --purple-700: #5d01c6;
    --purple-500: #981ee2;
    --purple-800: #6E2DB0;
    --purple-600: #5a189a;

    --red-500: #ef233c; 

    --blue-400: #086AD8;
    --blue-500: #00abf5;
    --blue-600: #0446F1;
    --blue-800: #000058;
    --blue-900: #010c2a;
  }
  
  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
    }
  }
  
  @media (max-width: 728px) {
    html {
      font-size: 87.5%;
    }
  }
  
  body {
    background: var(--white);
    color: var(--black-600);
  }
  
  body,
  input,
  textarea,
  select,
  button {
    font: 400 1rem "Poppins", sans-serif;
  }
  
  button {
    cursor: pointer;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }