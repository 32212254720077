.buttonChat {
    position: fixed;
    right: 15px;
    bottom: 20px;
    height: 60px;
    width: 60px;
    background-color: #f04e23;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    background-image: url("../../assets/images/chatboxes.png");
    background-repeat: no-repeat;
    background-position: center;  
  } 



.content {
  padding-left: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 1.5rem;
  flex: 1;
  margin-top: 90px;
  margin-left: 15rem;
}

.scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .content {
    margin-left: 0;
    height: 100vh;
  }
}
