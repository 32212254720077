.containerBasicData {
  display: grid;
  grid-gap: 1rem;
  width: 70%;
  margin-top: 2rem;
  padding: 1rem;
  background-color: var(--blue-900);
  border-radius: 0.75rem;
  box-shadow: 0px 2px 3px 3px rgba(0, 0, 0, 0.7);

  // Aqui definimos três colunas com tamanho igual
  grid-template-columns: repeat(2, 1fr);

  // Para telas menores, podemos mudar para duas ou uma coluna
  @media (max-width: 1024px) { // Para tablets
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) { // Para dispositivos móveis
    grid-template-columns: 1fr;
  }
}

.contentButtons {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 1rem;
}