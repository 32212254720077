.boxChildren {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    svg {
        margin-left: 10px;
        color: lightgrey;
    }
}

.table {
    th {
        padding: 0.35rem !important;
    }
}

.listActions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

@media (max-width: 768px) {
    .listActions {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
}