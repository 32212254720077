.containerStep {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    h2 {
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--gray-700);
    }

    p {
        font-size: 1rem;
        color: var(--gray-500);
    }
}