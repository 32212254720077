.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
}

.contentInput {
    display: flex;
    width: 100%;
    height: auto;
    background-color: var(--white);
    border-radius: 0.15rem;
    padding: 0.5rem;
    border: none;

    input {
        width: 100%;
        height: 100%;
        background-color: var(--white);
        border: none;
        outline: none;
        font: 400 1rem "Poppins", sans-serif;
    }

    svg {
        cursor: pointer;
    }
}

.labelInput {
    font: 400 0.95rem "Poppins", sans-serif;
    color: var(--white);
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .iconInfo {
        font-size: 1.5rem;
        cursor: pointer;
    }
}

.infoAlert {
    background: transparent;
    border: none;
    color: #FFFFFF;

    div {
        color: #FFFFFF !important;
    }
}