.ticket_details {
  padding: 1rem;
  width: 100%;
  height: 80vh;

  .comment_list {
    max-width: 600px;
    margin: 0 auto;
  }

  .ant_list_items {
    margin-top: 10px;
  }

  .ant_list_item_meta_description {
    color: #555; // Customize the text color
  }

  .upload_list_inline {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -8px;
  }

  .formChat {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    input {
      width: 100%;
    }
  }

  button {
    width: 80px; // Button width for consistency
  }

  .header_ticket {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding: 1rem 0.35rem;
    border-radius: 0.35rem;
    box-shadow: 0 5px 3px rgba(0, 0, 0, 0.3);
  }

  .contentDialog {
    width: 100%;
    height: 90%;
    max-height: 75%;
    padding: 2rem 1rem;
    overflow-y: auto;
    scrollbar-width: thin;

    .ballonTalk {
      position: relative;
      width: fit-content;
      max-width: 60%;
      min-width: 40%;
      padding: 1rem;
      border-radius: 0 0.5rem 0.5rem 0.5rem;

      .ownerTalk {
        position: absolute;
        margin: -3.3rem 0 0 -1rem;
        font-size: 1rem;
        font-weight: bold;
      }

      .dateRegister {
        position: absolute;
        margin: -2rem 0 0 -1rem;
        font-size: 0.65rem;
        font-weight: bold;
      }

      p {
        color: var(--white);
        font-size: 1.2rem;
      }

      & + .ballonTalkUser {
        margin-top: 2.5rem;
      }

      & + .ballonTalk {
        margin-top: 2.5rem;
      }
    }

    .ballonTalkUser {
      position: relative;
      width: fit-content;
      max-width: 60%;
      min-width: 50%;
      padding: 1rem;
      background-color: var(--white);
      border-radius: 0.5rem 0 0.5rem 0.5rem;
      margin-left: auto;

      .ownerTalk {
        position: absolute;
        margin-top: -3.3rem;
        right: 0;
        font-size: 1rem;
        font-weight: bold;
      }

      .dateRegister {
        position: absolute;
        margin-top: -2rem;
        text-wrap: nowrap;
        right: 0;
        font-size: 0.65rem;
        font-weight: bold;
      }

      p {
        color: var(--blue-800);
        font-size: 1.2rem;
      }

      & + .ballonTalk {
        margin-top: 2.5rem;
      }

      & + .ballonTalkUser {
        margin-top: 2.7rem;
      }
    }
  }

  .boxStatusAndPriority {
    display: flex;
    width: 45%;
    justify-content: flex-end;
    align-items: center;

    .boxInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 40%;
      border: 1px solid var(--blue-900);
      padding: 0.35rem;
      border-radius: 0.35rem;

      &:hover {
        cursor: pointer;
        box-shadow: 3px 5px 3px rgba(0, 0, 0, 0.3);
      }

      & + div {
        margin-left: 1rem;
      }
    }
  }
}

.boxAttachments {
  margin-top: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  a {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    img {
      border-radius: 0.25rem;
    }
  }
}

.spinLoading {
  position: fixed !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(22, 118, 253, 0.25) !important;
  border-radius: 4px;
  width: 10% !important;
  height: 15% !important;
  margin-left: 45% !important;
  margin-top: 10% !important;
}

@media (max-width: 768px) {
  .ballonTalk {
    max-width: 80% !important;
    min-width: 60% !important;
  }

  .ballonTalkUser {
    max-width: 80% !important;
    min-width: 60% !important;
  }

  .boxStatusAndPriority {
    width: 45% !important;
    justify-content: center;
    margin-top: 1rem;

    .boxInfo {
      width: 49% !important;
    }
  }
}

@media (max-width: 576px) {
  .header_ticket {
    div {
      :nth-child(2) {
        width: 50%;
      }
    }

    h2 {
      font-size: 1.25rem;
    }

    .boxStatusAndPriority {
      width: 30%;
    }
  }

  .contentDialog {
    .ballonTalk {
      .ownerTalk {
        max-width: 100%;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important; 
      }
    }

    .ballonTalkUser {
      .ownerTalk {
        max-width: 100%;
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important; 
      }
    }
  }
}

@media (max-width: 425px) {
  .header_ticket {
    div {
      :nth-child(2) {
        width: 69%;
      }
    }

    h2 {
      font-size: 1rem;
    }

    .boxStatusAndPriority {
      width: 30%;

      .boxInfo {
        width: 49%;
      }
    }
  }

  .ballonTalk {
    max-width: 90% !important;
    min-width: 70% !important;
  }

  .ballonTalkUser {
    max-width: 90% !important;
    min-width: 70% !important;
  }
}
