.containerLogin {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/images/BG1920-1080.png');
    background-size: cover;

    .circleGreen {
        position: absolute;
        top: -219px;
        left: -176px;
        width: 50rem;
        height: 50rem;
        border-radius: 100000px;
        
        img {
            width: 90%;
            clip-path: 'circle(50% at 50% 50%)';
        }
    }

    .circleBottom {
        position: absolute;
        bottom: -200;        /* Alinhado com a parte inferior da tela */
        right: -150;         /* Alinhado com a parte direita da tela */
        width: 50rem;
        height: 50rem;
        overflow: hidden;
    
        img {
            width: 100%;  /* Ajustado para preencher o contêiner .circleBottom */
            height: auto; /* Mantém a proporção da imagem */
            max-width: 90%; /* Garante que a imagem não ultrapasse 90% da largura de seu contêiner */
            overflow: hidden;
        }
    }

    .boxForm {
        display: flex;
        width: 1920px;
        max-width: 40%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background-color: var(--blue-900);
        border-radius: 0.15rem;
        padding: 0 2rem 2rem 2rem;
        z-index: 99;
        box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.7);

        img {
            width: 100%;
        }

        .boxRemember {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 1rem;

            .contentRemenber {
                display: flex;
                align-items: center;

                input[type="checkbox"] {
                    width: 1.5rem;
                    height: 1.5rem;
                    margin-right: 0.5rem;
                }

                label {
                    color: var(--white);
                    font-size: 1rem;
                }
            }

            .boxForgotPass {
                display: flex;
                justify-content: flex-end;
                width: 50%;
    
                .forgotPassword {
                    color: var(--white);
                    font-size: 1rem;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

    }

    .divider {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1rem 0;
        width: 100%;

        .dividerLine {
            width: 100%;
            height: 1px;
            background-color: var(--white);
        }

        .dividerText {
            color: var(--white);
            font-size: 1.2rem;
            margin: 0 1rem;
        }
    }

    .spinLoading {
        position: fixed !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(22, 118, 253, 0.25) !important;
        border-radius: 4px;
        top: 25% !important;
        width: 10% !important;
        height: 15% !important;
        margin-left: 45% !important;
        margin-top: 10% !important;
    }

    .boxButtonFlutuante {
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        right: 30px;
        bottom: 100px;
        width: 300px;
        z-index: 99;

        .cardInfo {
            box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.7);
        }
    }

    .titleHelpDesk {
        div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                color: var(--blue-900);
                font-size: 1.25rem;
            }

            svg {
                color: var(--blue-900);
                cursor: pointer;
            }
        }

        p {
            color: var(--blue-900);
            font-size: 1rem;
            text-align: left;
            flex-wrap: wrap;
            text-overflow: clip;
        }
    }
}

@media (max-width: 768px) {
    .containerLogin {
        .boxForm {
            max-width: 90%;
        }
    }
}