.containerBasicData {
  display: grid;
  grid-gap: 1rem;
  width: 100%;
  margin-top: 2rem;
  padding: 1rem;
  background-color: var(--blue-900);
  border-radius: 0.75rem;
  box-shadow: 0px 2px 3px 3px rgba(0, 0, 0, 0.7);

  // Defina as áreas de grid
  grid-template-areas:
    "emailArea emailArea emailArea emailArea"
    "passwordArea passwordArea confirmPasswordArea confirmPasswordArea";

  @media (max-width: 1024px) { // Para tablets
    // Em telas menores, ajuste as áreas de grid para se adaptar a duas colunas
    grid-template-areas:
      "emailArea emailArea"
      "passwordArea passwordArea"
      "confirmPasswordArea confirmPasswordArea";
  }

  @media (max-width: 768px) { // Para dispositivos móveis
    // Em telas ainda menores, ajuste as áreas de grid para se adaptar a uma coluna
    grid-template-areas:
      "emailArea"
      "passwordArea "
      "confirmPasswordArea";
  }
}

.grupo1 {
  grid-area: emailArea; // Associa o grupo1 à área 'emailArea'
}

.grupo2 {
  grid-area: passwordArea; // Associa o grupo2 à área 'passwordArea'
  
  // Isso assegura que os campos senha e confirmação estejam lado a lado na mesma linha
  display: grid;
  grid-template-columns: 2fr;
  gap: 1rem;

  @media (max-width: 768px) { // Para dispositivos móveis
    grid-template-columns: 1fr; // Em telas menores, ajuste para uma coluna
  }
}

.grupo3 {
  grid-area: confirmPasswordArea; // Associa o grupo2 à área 'passwordArea'
  
  // Isso assegura que os campos senha e confirmação estejam lado a lado na mesma linha
  display: grid;
  grid-template-columns: 2fr;
  gap: 1rem;

  @media (max-width: 768px) { // Para dispositivos móveis
    grid-template-columns: 1fr; // Em telas menores, ajuste para uma coluna
  }
}

.listInfos {
  li {
    color: var(--white);
  }
}

.contentButtons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1rem;
}
