.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: var(--blue-900);
  }

  form {
    width: 85%;
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
  }
}