.background {
  position: fixed;
  background-color: var(--blue-900);
  width: 100vw;
  z-index: 999;
  top: 0;
  height: 70px;
}

.navbar {
  top: 1rem;
  left: 2.5rem;
  right: 2.5rem;
  display: flex;
  align-items: center;
  z-index: 999;
  flex-direction: row;
  justify-content: flex-end;
  padding: 16px 24px;
  border-radius: 0.25rem;
  height: 70px;

  .rightContainer{
    height: 24px;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.2rem;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.orangeButton {
  font-weight: 600;
  color: var(--orange-700);
  font-size: 0.85rem;
  border-style: solid;
  padding: 0.25rem;
  border-width: 0.1rem;
  cursor: pointer;
}

.divider {
  height: 100%;
  width: 2px;
  background-color: var(--gray-100);
}

.leftContainer {
  flex-direction: row;
  flex-grow: 1;
  display: flex;
  //gap: 1rem;
  justify-content: flex-start;
}

.centerContainer {
  flex: 1;
  display: flex;
  justify-content: center;
}

.icon {
  color: var(--white);
  cursor: pointer;
  margin-left: 20px;
  transition: color 0.3s;

  &:nth-child(1) {
    margin-left: 0;
  }

  &:hover {
    color: var(--blue-600); /* Adapte a cor de destaque conforme necessário */
  }
}

.menu {
  display: none;
}

@media only screen and (max-width: 768px) {
  .menu {
    display: block;
  }
  .actions {
    display: none;
  }
  .logo {
   display: none;
  }
  .leftContainer {
    justify-content: center;
  }
  .menu {
    position: absolute;
    left: 1.5rem;
  }
}
