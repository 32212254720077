.calendarContainerBase {
  padding: 1rem 2rem;
  button {
    border: none !important;
    background-color: var(--blue-800) !important;
  }
}

.calendarContainerElume {
  padding: 1rem 2rem;
  button {
    border: none !important;
    background-color: var(--purple-800) !important;
  }
}

.formContainer {
  .formRow {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-direction: row;
  }

  .formItem {
    flex: 1;
  }

  .formButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 1rem;
  }

  .formButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    button[type="submit"] {
      width: 8rem;
      margin-top: 1rem;
      margin-left: 1rem;
      font-size: 16px;
      height: 40px;
      border: none;
    }

    button[type="button"] {
      width: 8rem;
      margin-top: 1rem;
      background-color: var(--red-500);
      font-size: 16px;
      color: var(--white);
      border: none;
      border-radius: 5px;
      height: 40px;
    }
  }
}

.carouselItem {
  display: flex !important;
  justify-content: center;
}

.carouselImage {
  max-width: 300px;
  max-height: 300px;
  object-fit: contain;
}

.infoContainer {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  text-align: flex-start;
}

.infoTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: bold;
}

.infoText {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
}

.infoTextBold {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  font-weight: bold;
}

.infoBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  text-align: flex-start;
  margin-top: 1rem;
}

.boxActions {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.imageLinkBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  text-align: flex-start;
}
