.container {
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: var(--blue-900);
    }
}